html { scroll-behavior: smooth; }
body { background-color: #2CBCF8; color: #000F85; font-weight: 300; }
/* body { background-color: #B10A11; color: #ffffff; font-weight: 300; } */

.Modal {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  bottom: auto;
  margin: auto;
  background-color: #fff;
  width: 86%;
  max-height: fit-content;
  padding: 0;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.25);
}

.Modal-header {
  border-radius: 4px 4px 0 0;
}

.Modal-header p {
  color: #ffffff;
}

.Overlay {
  /* z-index: 40;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  background-color: transparent;
}


.consent-modal {
  width: 100%;
  top: auto;
  bottom: 0;
}

.consent-modal a {
  text-decoration: underline;
}

.Overlay-consent {
  /* z-index: 40;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  background-color: transparent;
}

table {
  width: 100% !important;
}

.table-rule table, .table-rule th, .table-rule td,
.table-leaderboard table, .table-leaderboard th, .table-leaderboard td {
  border: 1px solid #2CBCF8;
  border-collapse: collapse;
}

.table-rule table tr,
.table-leaderboard table tr {
  background-color: #ffffff;
}

.table-leaderboard table tbody tr:nth-child(odd) {
  background-color: #EDEDED;
}

.table-leaderboard table tbody tr.row-user {
  background-color: #FFEFEF;
}

.table-rule table tr th, .table-rule table tr td,
.table-leaderboard table tr th, .table-leaderboard table tr td {
  color: #0a0a0a;
  padding: .4rem;
  font-size: .8rem;
}

.table-leaderboard table tr th {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
}

.table-leaderboard table tr td {
  font-weight: 400;
  font-size: .8rem;
  text-align: center;
}

.table-leaderboard table tr:first-child td {
  font-weight: 600;
}

.table-leaderboard table tr.row-loading:first-child td {
  font-weight: 400;
}


.table-leaderboard table tr td:nth-child(2) {
  text-align: left;
}

/* Rule Page */
.rule h2 { font-size: 1.4rem; font-weight: 400; margin-bottom: .4rem }
.rule h3 { font-size: 1.2rem; margin-bottom: .4rem }
.rule hr { margin-bottom: .6rem;}

.rule ol {
  list-style: decimal;
  padding-left: 1rem;
  margin-bottom: 3rem;
}

.rule ul {
  list-style: disc;
  padding-left: 1rem;
  margin-bottom: 3rem;
}

.rule li {
  font-weight: 300;
}